import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Download  V18.10 2024(Anti-Ban)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Introduce GB WhatsApp and offer Secure version of GBWhatsApp that has resolved login issues. Download and Update GBWhatsApp by AlexMODs, which has extra features like the best AI image generator. `,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat'
				}
			]
		},
		component: Home,
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/downloadpage',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp  Pro | GBWhatsApp Download | GB WhatsApp Update`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Here we support GB WhatsApp, GBWhatsApp Pro, WhatsApp Plus, YO WhatsApp, and FM WhatsApp Download and Update methods for Latest Version.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/downloadpage/'
				}
			]
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: ` GBWhatsApp | Why do people prefer GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/blogs/'
				}
			]
		},
		component: () => import('@/views/blogs.vue'),
	},
	{
		path: '/blog-1',
		name: 'blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp | How to use same WhatsApp Account on Different phone?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/blog-1/'
				}
			]
		},
		component: () => import('@/views/blog1.vue'),
	},
	{
		path: '/blog-2',
		name: 'blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp | GB WhatsApp Data Recovery Way`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/blog-2/'
				}
			]
		},
		component: () => import('@/views/blog2.vue'),
	},
	{
		path: '/blogs/GBWhatsApp-Channels',
		name: 'blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp | WhatsApp Channels｜How to create a WhatsApp Channel`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/blogs/GBWhatsApp-Channels/'
				}
			]
		},
		component: () => import('@/views/blog3.vue'),
	},
	{
		path: '/blog-4',
		name: 'blog4',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp｜Is GB WhatsApp safe?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/blog-4/'
				}
			]
		},
		component: () => import('@/views/blog4.vue'),
	},
	{
		path: '/blog-5',
		name: 'blog5',
		meta: {
			appname: 'GBWhatsApp',
			title: `The 10 Best Apps for Spying someone else's WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/blog-5/'
				}
			]
		},
		component: () => import('@/views/blog5.vue'),
	},
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/privacy/'
				}
			]
		},
		component: () => import('@/views/privacy.vue'),
	},
	{
		path: '/ar',
		name: 'ar-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK تنزيل الإصدار V18.10 2024 لشهر يونيو`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `واتساب الذهبي هو تطبيق رائع لأي شخص يريد تجربة واتساب أكثر تخصيصًا وغنية بالميزات. قم بتنزيل واتساب الذهبي للحصول على الإصدار الثابت.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/ar/'
				}
			]
		},
		component: () => import('@/views/ar.vue'),
	},
	{
		path: '/id',
		name: 'id-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Unduh Versi V18.10 Juni 2024`,
			description: `GBWhatsApp Pro adalah aplikasi yang hebat untuk siapa saja yang menginginkan pengalaman WhatsApp yang lebih personal dan kaya fitur. Unduh GBWhatsApp untuk versi yang sudah diperbaiki.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/id/'
				}
			]
		},
		component: () => import('@/views/id.vue'),
	},
	{
		path: '/po',
		name: 'po-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Download Versão V18.10 Junho 2024`,
			description: `GBWhatsApp Pro é um ótimo aplicativo para quem deseja uma experiência do WhatsApp mais personalizada e rica em recursos. Baixe o GBWhatsApp para a versão corrigida.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/po/'
				}
			]
		},
		component: () => import('@/views/po.vue'),
	},
	{
		path: '/tr',
		name: 'tr-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK İndir V18.10 2024 Haziran Sürümü`,
			description: `GBWhatsApp Pro, daha kişiselleştirilmiş ve özellik açısından zengin bir WhatsApp deneyimi isteyen herkes için harika bir uygulamadır. Düzeltme sürümü için GBWhatsApp'ı indirin.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/tr/'
				}
			]
		},
		component: () => import('@/views/tr.vue'),
	},
	{
		path: '/in',
		name: 'in-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK डाउनलोड V18.10 2024 जून संस्करण`,
			description: `GBWhatsApp Pro उन सभी के लिए एक शानदार ऐप है जो अधिक व्यक्तिगत और सुविधाओं से भरपूर WhatsApp अनुभव चाहते हैं। फिक्स्ड संस्करण के लिए GBWhatsApp डाउनलोड करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/in/'
				}
			]
		},
		component: () => import('@/views/in.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapp.chat/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
